<template>
  <div class="operate-detail">
    <div class="header">
      <div class="w title">{{ form.parkTitle }}</div>
    </div>
    <!-- banner区域 -->
    <top-item :form="form"/>
    <!-- 园区概况 -->
    <overview :form="form"/>
    <!-- 服务内容 -->
    <content-item v-if="form.serviceContent" :form="form"/>
    <!-- 建筑及设施标准 -->
    <standard :form="form" :isIndex="isIndex"/>
  </div>
</template>

<script>
import TopItem from './componets-detail/top.vue';
import overview from './componets-detail/overview.vue';
import ContentItem from './componets-detail/content.vue';
import standard from './componets-detail/standard.vue';
import { onMounted, reactive, toRefs } from 'vue';
import apis from '@/request/apis';

export default {
  props: ['id', 'isIndex'],
  components: {
    TopItem,
    overview,
    ContentItem,
    standard,
  },
  setup(props) {
    const data = reactive({
      form: {},
    });
    const detail = async () => {
      const res = await apis.operate.getDetail2({
        id: props.id,
      });
      data.form = res;
    };
    detail();
    onMounted(() => {
      const app = document.querySelector('#app');
      app.scrollTop = 0;
    });
    return {
      ...toRefs(data),
    };
  },
};
</script>

<style scoped lang="scss">
.operate-detail {
  margin-top: 5.208vw;
  padding-bottom: 1.979vw;
  .header {
    height: 4.688vw;
    width: 100vw;
    background-color: var(--baseColor1);
    margin-bottom: 3.75vw;
    line-height: 4.688vw;
    .title {
      height: 100%;
      font-size: 1.25vw;
      font-family: Microsoft YaHei-Bold, Microsoft YaHei;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 4.688vw;
    }
  }
}
@media screen and (max-width: 768px) {
  .operate-detail {
    margin-top: 10.667vw;
    padding-bottom: 1.979vw;
    .header {
      height: 6.688vw;
      width: 100vw;
      background-color: var(--baseColor1);
      margin-bottom: 0;
      line-height: 6.688vw;
      .title {
        height: 100%;
        font-size: 2.4vw;
        font-family: Microsoft YaHei-Bold, Microsoft YaHei;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 6.688vw;
        margin-left: 2.667vw;
      }
    }
  }
}
</style>
